/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useLayoutEffect } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { toast } from 'utils/toast';
import { apiRefresh } from './auth/index';
import { captureException } from '@sentry/react';
import { axiosLogger } from 'Axioslogger';

export const tzAxios = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  validateStatus(status) {
    return status >= 200 && status <= 201;
  },
  headers: {
    'X-Custom-Language': 'ar',
  },
});

tzAxios.interceptors.request.use((request) => {
  axiosLogger('Request for Axios Logger: ', request);
  return request;
});

// Response interceptor
tzAxios.interceptors.response.use(
  (response) => {
    axiosLogger('Response for Axios Logger: ', response);
    return response;
  },
  (error) => {
    axiosLogger('Error Response:', error.response);
    captureException(error);
    return Promise.reject(error);
  },
);

export const useInterceptors = () => {
  const authHeader = useAuthHeader();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleResponseSuccess = (response: AxiosResponse) => response;

  const handleErrorCodes = useCallback(
    async (error: AxiosError<{ message: string }>) => {
      if (error?.response?.status) {
        switch (error.response.status) {
          case 500:
          case 404:
          case 403:
            navigate(ROUTES.home);
            toast({
              title: formatMessage({
                id: 'global.error.generictitle',
                defaultMessage: 'Something went wrong!',
              }),
              description: error.response.data.message || error.message,
              status: 'error',
            });
            break;
          case 401:
            await handle401Error(error);
            break;
          case 429:
            toast({
              title: formatMessage({
                id: 'global.error.generictitle',
                defaultMessage: 'Too Many Requests',
              }),
              description: error.response.data.message || error.message,
              status: 'error',
            });
            break;
          default:
            break;
        }
      }

      // Capture the error in Sentry
      captureException(error);
    },
    [navigate, formatMessage],
  );

  useLayoutEffect(() => {
    tzAxios.defaults.headers.common['X-Custom-Language'] = 'ar';
    tzAxios.defaults.headers.common.Authorization = authHeader();

    queryClient.invalidateQueries();

    const interceptorId = tzAxios.interceptors.response.use(handleResponseSuccess, (error) => {
      handleErrorCodes(error);
      return Promise.reject(error);
    });

    return () => {
      tzAxios.interceptors.response.eject(interceptorId);
    };
  }, [authHeader, queryClient, handleErrorCodes]);
};

async function handle401Error(error: AxiosError) {
  const refreshToken = localStorage.getItem('_auth_refresh');
  if (refreshToken) {
    try {
      const accessToken = await refreshAccessToken(refreshToken);
      if (accessToken && error.config) {
        updateAccessTokenAndHeaders(accessToken, error.config);
        if (!(error.config.url && error.config.url.endsWith('/checkout/'))) {
          location.reload();
          return tzAxios(error.config as AxiosRequestConfig);
        }
        return tzAxios(error.config as AxiosRequestConfig);
      }
    } catch (err) {
      captureException(err);
      console.error('Error refreshing token', err);
    }
  }

  Navigate({ to: ROUTES.home });
}

async function refreshAccessToken(refreshToken: string) {
  const data = { refreshToken: refreshToken };
  const response = await apiRefresh(data);
  return response.accessToken;
}

function updateAccessTokenAndHeaders(accessToken: string, config: AxiosRequestConfig) {
  localStorage.setItem('_auth', accessToken);
  tzAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  if (config && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
}
