import { sendRequest } from 'betterStackConsole';
import { useCallback, useEffect, useState } from 'react';
import { useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { apiQiToken } from '..';
import { THIRTY_DAYS_IN_SECONDS } from 'utils/constants';
import { toast } from 'utils/toast';
import { apiAlipayAuthcode } from '../AliPayAuth/AliPayAuth';
import { captureException } from '@sentry/react';
import useHylideBridge from 'hooks/useHylideBridge';

interface SuccessProps {
  authCode: string;
}

export const useSignInOnce = () => {
  const userToken: any = window.qi.user?.mobile.token;
  const hylideBridge = useHylideBridge();
  const signIn = useSignIn();
  const [signInInitiated, setSignInInitiated] = useState(false);
  const [authcode, setAuthCode] = useState<string | null>(null);
  const isAuthenticated = useIsAuthenticated();

  const handleSignInError = (error: any) => {
    toast({
      status: 'error',
      description: JSON.stringify(error.message),
    });
    captureException(JSON.stringify(error));
  };

  const signInWithQiServicesToken = useCallback(async () => {
    try {
      const data = await apiQiToken({ token: userToken });
      sendRequest(data.accessToken);

      signIn({
        token: data.accessToken,
        expiresIn: data.expiresIn,
        tokenType: data.type,
        refreshToken: data.refreshToken,
        refreshTokenExpireIn: THIRTY_DAYS_IN_SECONDS,
      });

      toast({
        status: 'success',
        description: `Successfully signed in!`,
      });
    } catch (error) {
      handleSignInError(error);
    }
  }, [signIn, userToken]);

  const signInwithSuperQiAuthCode = useCallback(async () => {
    try {
      if (hylideBridge) {
        hylideBridge.getAuthCode({
          scopes: ['USER_CONTACTINFO', 'auth_user'],
          success: async (res: SuccessProps) => {
            const authCode = res.authCode;
            setAuthCode(authCode);

            try {
              const response = await apiAlipayAuthcode({ auth_code: authCode });
              const accessToken = response.accessToken;

              if (accessToken) {
                signIn({
                  token: accessToken,
                  expiresIn: response.expiresIn,
                  tokenType: response.type,
                  refreshToken: response.refreshToken,
                  refreshTokenExpireIn: response.expiresIn,
                  authState: { phone: '', session: '', name: '', email: '', gender: '' },
                });
              } else {
                captureException('Access token not found in the response.');
              }
            } catch (error) {
              handleSignInError(error);
              captureException(error);
            }
          },
          fail: (error) => {
            handleSignInError(error);
            captureException(error);
          },
        });
      }
    } catch (error) {
      handleSignInError(error);
      captureException(error);
    }
  }, [signIn, setAuthCode, hylideBridge]);

  const authentication = useCallback(async () => {
    if (userToken) {
      await signInWithQiServicesToken();
    } else {
      await signInwithSuperQiAuthCode();
    }
  }, [signInWithQiServicesToken, signInwithSuperQiAuthCode, userToken]);

  useEffect(() => {
    if (!isAuthenticated() && !signInInitiated) {
      authentication();
      setSignInInitiated(true);
    }
  }, [
    authcode,
    isAuthenticated,
    signIn,
    signInInitiated,
    signInWithQiServicesToken,
    signInwithSuperQiAuthCode,
    authentication,
  ]);

  return null;
};
