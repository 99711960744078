import { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';
import { captureException } from '@sentry/react';
import useHylideBridge from 'hooks/useHylideBridge';

type AuthCode = string | null;

const userToken: any = window.qi.user?.mobile.token;

// Create a context to hold the authcode state
const AuthCodeContext = createContext<AuthCode>(null);

// Create a provider component to wrap your application
export const AuthCodeProvider = ({ children }: { children: ReactNode }) => {
  const [authcode, setAuthCode] = useState<AuthCode>(null);
  const hylideBridge = useHylideBridge();
  // Function to copy auth code
  const getAuthCode = () => {
    if (hylideBridge) {
      hylideBridge.getAuthCode({
        scopes: ['USER_CONTACTINFO', 'auth_user'],
        success: async (res: { authCode: string }) => {
          const authCode = res.authCode;
          setAuthCode(authCode);
        },
        fail: (error: string) => {
          !userToken && captureException(JSON.stringify(error));
        },
      });
    }
  };

  useEffect(() => {
    getAuthCode();
  });

  return <AuthCodeContext.Provider value={authcode}>{children}</AuthCodeContext.Provider>;
};

// Custom hook to access authcode anywhere in the app
export const useAuthCode = (): AuthCode => useContext(AuthCodeContext);
